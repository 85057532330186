<template>
  <div>
    <b-modal
      id="create-mentee-profile-as-mentor-modal"
      ref="create-mentee-profile-as-mentor-modal"
      centered
      size="md"
      :ok-title="$t('Create mentee profile')"
      ok-only
      @ok.prevent="createMenteeProfile"
    >
      <template #modal-title>
        <span class="font-weight-bolder">{{ $t('Create mentee profile') }}</span>
      </template>
      <b-row>
        <b-col>
          <div v-if="fromMentoringPackages">
            <b-card-text class="mb-1">
              {{ $t('You must accept terms and conditions to create a mentee profile - mentoring packages') }} {{ $t('You can switch profile whenever you want.') }}
            </b-card-text>
          </div>
          <div v-if="fromMenteeRegistration">
            <b-card-text class="mb-1">
              {{ $t('You must accept terms and conditions to create a mentee profile - mentee registration') }}
            </b-card-text>
            <b-card-text class="mb-1">
              {{ $t('You can switch profile whenever you want.') }}
            </b-card-text>
          </div>
          <div class="mb-1">
            <b-form-checkbox
              v-model="giveConsent"
              value="1"
            >
              {{ $t('MentorGiveConsent') }}
            </b-form-checkbox>
          </div>
          <validation-observer ref="create-mentee-profile-as-mentor-observer">
            <validation-provider
              v-slot="{ errors }"
              name="acceptTermsAndConditions"
              :rules="{
                required: {
                  allowFalse: false
                }
              }"
              mode="lazy"
            >
              <b-form-checkbox
                v-model="acceptTermsAndConditions"
                value="1"
              >
                {{ $t('I agree to') }}
                <b-link
                  :to="{ name: 'mentoring-terms' }"
                  target="_blank"
                >
                  {{ $t('the terms and conditions of WHEN') }}
                </b-link>
                {{ $t('and') }}
                <b-link
                  :to="{ name: 'privacy-policy' }"
                  target="_blank"
                >
                  {{ $t('the privacy policy') }}
                </b-link>
                {{ $t('as a mentee') }} <required-field /><br>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-checkbox>
            </validation-provider>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  BRow,
  BCardText,
  BCol,
  BFormCheckbox,
  BLink,
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@mentoring-platform-validations'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'

export default {
  components: {
    BRow,
    BCardText,
    BCol,
    BFormCheckbox,
    BLink,
    BModal,
    RequiredField,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    fromMentoringPackages: {
      type: Boolean,
      default: false,
    },
    fromMenteeRegistration: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      acceptTermsAndConditions: false,
      giveConsent: false,
    }
  },
  methods: {
    show() {
      this.$refs['create-mentee-profile-as-mentor-modal'].show()
    },
    collectData() {
      return {
        give_consent: this.giveConsent,
      }
    },
    createMenteeProfile() {
      this.$refs['create-mentee-profile-as-mentor-observer'].validate().then(async success => {
        if (success) {
          this.acceptTermsAndConditions = false
          this.$refs['create-mentee-profile-as-mentor-modal'].hide()

          const data = this.collectData()
          const menteeProfileCreated = await this.$store.dispatch('user/mentorCreateMenteeProfile', data)
          if (menteeProfileCreated) {
            this.$root.$bvToast.toast(this.$t('You now participate in our network as a mentee'), {
              title: this.$t('Congratulations!'),
              variant: 'success',
              solid: true,
            })
            this.$store.dispatch('user/me')
          } else {
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">

</style>
