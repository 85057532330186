<template>
  <div>
    <h3 class="font-weight-bolder text-center mb-3">
      {{ $t('AdditionalInfo') }}
    </h3>
    <app-collapse
      accordion
      type="margin"
    >
      <app-collapse-item
        v-for="(question, index) in questions"
        :key="index"
        title=""
      >
        <template #header>
          <b>{{ $t(question.question) }}</b>
        </template>
        <!-- eslint-disable-next-line -->
        <span v-html="$t(question.answer)"></span>
      </app-collapse-item>
    </app-collapse>
    <b-card-text
      class="mt-1"
      style="font-size: 15px;"
    >
      {{ $t('Click here to learn more about purchasing a package') }}
      <b-link :to="{ name: 'mentoring-terms' }">
        {{ $t('here') }}
      </b-link>
    </b-card-text>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCardText,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCardText,
    BLink,
  },
  data() {
    return {
      questions: [
        {
          id: 1,
          question: 'Faq - mentoring-in-general - question 1',
          slug: 'how-secure-is-my-password',
          answer: 'Faq - mentoring-in-general - answer 1',
        },
        {
          id: 6,
          question: 'Faq - for-mentees - question 20',
          slug: 'can-i-change-my-username',
          answer: 'Faq - for-mentees - answer 20',
        },
        {
          id: 2,
          question: 'Faq - mentoring-in-general - question 4',
          slug: 'how-do-i-change-my-timezone',
          answer: 'Faq - mentoring-in-general - answer 4',
        },
        {
          id: 3,
          question: 'Faq - for-mentees - question 2',
          slug: 'can-i-change-my-username',
          answer: 'Faq - for-mentees - answer 2',
        },
        {
          id: 4,
          question: 'Faq - for-mentees - question 3',
          slug: 'can-i-change-my-username',
          answer: 'Faq - for-mentees - answer 3',
        },
        {
          id: 5,
          question: 'Faq - for-mentees - question 4',
          slug: 'can-i-change-my-username',
          answer: 'Faq - for-mentees - answer 4',
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>
